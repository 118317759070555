//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {TheMask} from 'vue-the-mask';
import Loader from "@/components/main/Loader";

export default {
  name: "PinCode",
  components: {TheMask, Loader},
  props: {
    id: String,
    error: Boolean,
    title: String,
    placeholder: String,
    value: String,
    setValue: Function,
    cleanErrors: Function,
    getPasswordError: Function,
    setPasswordError: Function,
    pending: Boolean,
  },
}
