//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/api/api";
import $ from "jquery";
import { mapMutations } from 'vuex';

import notification from "@/notification/notification";
import Loader from '../../../main/Loader.vue';
import SignCard from "@/components/documents/table/SignCard";
import TwoUsersIcon from "@/components/icons/TwoUsersIcon";
import MinTrud from "@/components/icons/MinTrud";

const headers = [
  {
    key: 'index',
    label: '',
  },
  {
    key: 'date',
    label: 'Дата',
    sortable: true,
  },
  {
    key: 'docId',
    label: 'Номер',
  },
  { 
    key: 'name',
    label: 'Тип',
    tdClass: 'alignStart',
  },
  {
    key: 'signed',
    label: 'Подпись'
  }
];

export default {
  name: "ExternalDocumentsTable",
  components: { TwoUsersIcon, Loader, MinTrud, SignCard },
  props: {
    documents: Array,
    getDocuments: Function,
    changeDocument: Function,
  },
  data() {
    return {
      headers: headers,
      headersMobile: [headers[0], headers[1]],
      selectedDocuments: [],
      signRules: {},
    }
  },
  mounted() {
    this.getSignRules();
  },
  methods: {
    handleSelectDocs(value) {
      const foundIndex = this.selectedDocuments.findIndex(selected => selected === value);
      if (foundIndex >= 0) this.selectedDocuments.splice(foundIndex, 1);
      else this.selectedDocuments.push(value);
    },
    isChecked(id) {
      this.selectedDocuments.includes(id);
    },
    couldBeSigned(document) {
      if(!document.regNum && document.needRegNum) return false;
      return true;
    },
    countScans(scans) {
      return scans.length
    },
    getPercent(document) {
      return `${this.$t('external_education.signed')} ${(document.dsProcessedByCount / document.dsSignersCount) * 100}%`;
    },
    getSignedClass(document) {
      if(!document.dsProcessedByCount) return 'dangerCount';
      if (document.dsProcessedByCount === document.dsSignersCount) return 'warningCount';
      return 'successCount';
    },
    viewDocument() {
      const response = api.getDocumentsPreview(this.selectedDocuments);
      response.then(res => {
        if (!res.data.result || !res.data.result.previewLink) {
          return
        }
        window.location.href = res.data.result.previewLink;
      })
    },    
    showGetArchiveModalSelected() {
      this.setIdDocuments(this.selectedDocuments);
      $('#modalGetArchive').modal('show');
    },
    getDocumentDate(date, factDate) {
      if (factDate == "") {
        return date;
      }
      return factDate;
    },
    showScansPreviews(docs) {
      this.$router.push({
        name: 'document-view',
        query: {
          type: "external_education",
        },
        params: {
          docs,
        }
      })
    },
    getSignRules() {
      this.setExternalDocumentLoading(true);
      const response = api.getSignRulesForType([]);
      response.then((res) => {
        if (res.data.error && res.data.error === 401) {
          this.$router.push({
            name: 'login',
          })
        } else if (res.data.error && res.data.errorStr) {
          notification.error(res.data.errorStr);
        } else {
          this.signRules = res.data.result.rules;
        }
      }).finally(() => {
        this.setExternalDocumentLoading(false);
      }).catch((e) => {
        console.log(e);
      })
    },
    ...mapMutations([
      'setExternalDocumentLoading',
      'setIdDocuments'
    ])
  }
}
