//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';

import RestrictedMessage from '@/components/errorBlocks/RestrictedMessage';
import Materials from './Materials.vue';
import Loader from '../main/Loader';
import util from '../../utils'
import config from '../../../app.config';

export default {
  name: "Content",
  components: {RestrictedMessage, Loader, Materials},
  async mounted() {
    this.isFirstNotPassed = false;
    const params = this.$route.params.type
      ? { extNeedId: this.$route.params.id } 
      : { needId: this.$route.params.id };

    await this.getCourse(params);
    this.courseObj = { courseId: this.course.ID, needId: this.course.PARAMS && (this.course.PARAMS.extNeedId || this.course.PARAMS.needId ) };
  },
  watch: {
    currentStudent(prev, current) {
      if (prev.ID !== current.ID)
        this.$router.history.push({ name: 'courses' });
    }
  },
  data() {
    return {
      statusClasses: {
        notPassed: 'bg-warning',
        notAllowed: 'bg-dark',
        notViewed: 'bg-gray',
      },
      isFirstNotPassed: false,
      config,
      routerLink: this.$route.params.type ? `/external_education` : '/',
      courseObj: { },
      cancelToken: null,

    }
  },
  methods: {
    statusBarClass(item) {
      if (!item.TYPE) {
        item.TYPE = 'test';
      }

      switch (item.TYPE) {
        case 'lesson':
          if (item.PASSED) {
            if (item.INDEX === 1) {
              return 'percent-points__point percent-points__point--big percent-points__point--complete';
            } else {
              return 'percent-points__point percent-points__point--complete';
            }
          } else {
            if (this.isFirstNotPassed) {
              return 'percent-points__point bg-secondary';
            } else {
              this.isFirstNotPassed = true;
              return 'percent-points__point percent-points__point__complete bg-danger';
            }
          }
        case 'test':
          if (item.PASSED) {
            return 'percent-points__point bg-warning percent-points__point--complete';
          } else {
            if (this.isFirstNotPassed) {
              return 'percent-points__point bg-secondary';
            } else {
              this.isFirstNotPassed = true;
              return 'percent-points__point percent-points__point__complete bg-danger';
            }
          }
      }
    },
    ...mapActions([
      'getCourse'
    ])
  },
  computed: {
    progressBarMaterials() {
      let progressMaterials = this.materials.map((material) => {
        return material
      })

      if (this.course.FINAL_TESTS) {
        for (let finalTest of this.course.FINAL_TESTS) {
          progressMaterials.push(finalTest);
        }
      }

      return progressMaterials;
    },
    materials() {
      let materials = [];

      if (!this.course.GROUPS && (!this.course.ROOT || !this.course.ROOT.MATERIALS)) {
        return [];
      }

      for (let group of this.course.GROUPS) {
        for (let material of group.MATERIALS) {
          materials.push(material);
        }
      }

      if (util.isIterable(this.course.ROOT.MATERIALS)) {
        for (let material of this.course.ROOT.MATERIALS) {
          materials.push(material);
        }
      }

      return materials;
    },
    
    /**
     * @returns {boolean}
     */
    isFinalTestBlock() {
      return ('FINAL_TESTS' in this.course) && this.course.FINAL_TESTS;
    },

    ...mapState([
      'showCourseLoader',
      'course',
      'courseError',
      'currentStudent',
      'courseRestricted',
    ])
  },
}
