//
//
//
//

export default {
  name: "DocMaterial",
  props: {
    src: String
  },
}
