//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import { mapActions } from 'vuex';

import ExternalDocumentsTrainingModal from './ExternalDocumentsTrainingModal.vue';

const ENEED_STATUS = {
  notComplete: 'notcomplete',
  complete: 'complete',
  expired: 'expired',
}

export default {
  name: "ExternalNeed",
  components: { ExternalDocumentsTrainingModal },
  props: {
    need: {
      type: Object,
    },
    ecCompany: {
      type: Object,
    },
    index: {
      type: Number,
    }
  },
  data() {
    return {
      showCollapse: false,      
      showModal: false,
      selectedTraining: null,
      showNeedsInfo: false,
    }
  },
  created() {
    this.showNeedsInfo = this.need.STATUS.UF_CODE !== ENEED_STATUS.complete;
  },
  computed: {
    getDateEnd(){
      return (this.need && this.need.DATE_END) && moment(this.need.DATE_END, 'DD.MM.YYYY hh:mm:ss').locale('ru').format('DD.MM.YYYY');
    },
    getDateStartEducation(){
      return (this.need && this.need.DATE_START_EDUCATION) && moment(this.need.DATE_START_EDUCATION, 'DD.MM.YYYY hh:mm:ss').locale('ru').format('DD.MM.YYYY');
    },
    getStatusClass() {
      switch (this.need.STATUS.UF_CODE) {
        case (ENEED_STATUS.notComplete):
          if (this.need.HISTORY.length)
            return 'complete_before';
          return 'notComplete';
        case (ENEED_STATUS.complete):
          if (this.need.DATE_END)
            return this.isBetween ? 'complete_before' : 'before';
          return 'complete';
        case (ENEED_STATUS.expired):
          return 'expired'
        default:
          return 'unknown' 
      }
    },
    isBetween() {
      const dateStart = moment(this.need.DATE_END, 'DD.MM.YYYY').subtract(1, 'month');
      const dateEnd = moment(this.need.DATE_END, 'DD.MM.YYYY');
      const isBetween = moment().startOf('day').isBetween(dateStart, dateEnd, undefined, "[]");
      return isBetween;
    },
  },
  methods: {
    showTrainingModal(id) {
      this.showModal = true;
      this.selectedTraining = id;
    },
    hideTrainingModal() {
      this.showModal = false;
      this.selectedTraining = null;
    },
    async openCourse() {
      this.$router.push({ name: 'course', params: { id: Number(this.need.PARAMS.VALUE), type: 'ext' }});
    },
    ...mapActions(['getCourse']),
  },
}
