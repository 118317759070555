//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SignerHistory from "./SignerHistory.vue";

export default {
  name: "SignHistory",
  components: { SignerHistory },
  props: {
    document: Object,
  },
  data() {
    return {
      signHistory: {},
      signColorStatus: '',
    }
  },
  created() {
    const signedSigners = Object.keys(this.document.signHistory);
    if (signedSigners.length !== this.document.signers.length) {
      const notSignedSigners = this.document.signers.filter((signer) => {
        return !signedSigners.includes(signer.workerId);
      });
      notSignedSigners.forEach((notSignedSigner) => {
        const newProp = {
          comment: null,
          date: {
            date: '',
          },
          eventType: 'unprocess',
          eventTypeTranslated: 'Не подписано',
          signType: 'not_signed',
          signTypeTranslated: 'Не подписано',
          userFIO: `${notSignedSigner.surName} ${notSignedSigner.firstName} ${notSignedSigner.middleName}`,
          userID: notSignedSigner.workerId,
          worker: {
            company: notSignedSigner.sign && notSignedSigner.sign.company || '-',
            department: notSignedSigner.sub || '-',
            fio: `${notSignedSigner.surName} ${notSignedSigner.firstName} ${notSignedSigner.middleName}`,
            position: notSignedSigner.pos || '-',
            subdivision: notSignedSigner.sub || '-',
            workerId: notSignedSigner.workerId || '-',
          }
        }
        this.$set(this.document.signHistory, notSignedSigner.workerId, { 'not_signed' : [newProp] });
      })
    }
  },
  updated() {

  },  
  mounted() {
    this.getColorStatus(history.progress);
  },
  methods: {
    getColorStatus(progress) {
      if (progress === 0) this.signColorStatus = 'notSigned';
      if (progress === 100) this.signColorStatus = 'fullySigned';
      if (progress !== 100 && progress !== 0) this.signColorStatus = 'partiallySigned';
    }
  },
  computed: {
    calculateSignPercent() {
      if (this.document && this.document.dsSignersCount > 0) {
        return Math.floor(this.document.dsProcessedByCount / this.document.dsSignersCount * 100);
      }
      return 0;
    },
  }
}
