//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";

export default {
  name: "NavLinks",
  computed: {
    links(){
      const firstTabs = [
        {
          name: this.$i18n.t('nav_links.courses'),
          link: {
            name: 'courses',
          },
          isActive: this.isActive('courses'),
          icon: 'fa-graduation-cap'
        },
        {
          name: this.$i18n.t('nav_links.external_education'),
          link: {
            name: 'external_education',
          },
          isActive: this.isActive('external_education'),
          icon: 'fa-user-tie'
        },
        {
          name: this.$i18n.t('nav_links.documents'),
          link: {
            name: 'documents',
          },
          isActive: this.isActive('documents'),
          icon: 'fa-list'
        },
      ]

      if (this.showDocumentsFirst) {
        firstTabs.reverse()
      }

      return [
        ...firstTabs,
        {
          name: this.$i18n.t('nav_links.trainings'),
          link: {
            name: 'trainings',
          },
          isActive: this.isActive('trainings'),
          icon: 'fa-book',
          target: '_blank',
        },
      ];
    },
    ...mapState([
      'showDocumentsFirst'
    ])
  },

  methods: {
    isActive(linkName) {
      return this.$router.currentRoute.name === linkName
    },
    clearExtCourse(link) {
      console.log("clearExtCourse: ", link);
      if (link.link.name === 'courses')
        this.$store.commit('setCourse', {});
    }
  }
}
