//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';

export default {
  name: "SignHistoryCard",
  props: ['signHistory'],
  data() {
    return {
      collapseVisible: false,
      history: null,
      format: "YYY-MM-DD HH:MM:SS"
    }
  },
  created() {
    const history = [];
    const signHistory = Object.values(this.signHistory) || [];
    
    signHistory.forEach(signType => 
      signType.forEach( sign => { 
        history.push({
          date: sign.date.date && sign.date.date.split('.')[0], 
          status: sign.eventType, 
          title: `${sign.eventTypeTranslated} ${sign.signTypeTranslated}`
        });
      }
    ));

    if (history.length > 1) {
      history.sort((prev, next) => {
        return moment(prev.date, this.format)
          .diff(moment(next.date, this.format));
      })
    }
    this.history = history;
  }
}
